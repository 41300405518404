import { StateCreator } from 'zustand';

export type AudioSetting = {
    isAudioOn: boolean;
    toggleAudio: () => void;
    audioListeners: Set<(isOn: boolean) => void>;
};

export type MicSetting = {
    isMicOn: boolean;
    micMeta?: { trigger: 'click' | 'spacebar' };
    toggleMic: (meta?: MicSetting['micMeta']) => void;
    turnMicOn: (meta?: MicSetting['micMeta']) => void;
    turnMicOff: (meta?: MicSetting['micMeta']) => void;
    micListeners: Set<(isOn: boolean) => void>;
};

export type CaptionSetting = {
    isCaptionsOn: boolean;
    toggleCaptions: () => void;
};

export type ModeSetting = {
    isTextMode: boolean;
    toggleTextMode: () => void;
    setIsTextMode: (isTextMode: boolean) => void;
};

export type AttachmentSetting = {
    attachmentToDisplay: AttachmentToDisplay | undefined;
    setAttachment: (attachment: AttachmentToDisplay) => void;
};

export type SourcesSidePanelSetting = {
    isSourcesDrawerOpen: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
};

export type InteractionSettingsStore = AudioSetting &
    CaptionSetting &
    ModeSetting &
    MicSetting &
    AttachmentSetting &
    SourcesSidePanelSetting;

export const createInteractionSettingsSlice: StateCreator<InteractionSettingsStore> = (
    set,
    get,
) => ({
    audioListeners: new Set(),
    isAudioOn: true,
    toggleAudio: () =>
        set(state => {
            const isAudioOn = !state.isAudioOn;
            get().audioListeners.forEach(listener => listener(isAudioOn));
            return { isAudioOn };
        }),

    micListeners: new Set(),
    isMicOn: false,
    micMeta: undefined,
    toggleMic: meta =>
        set(state => {
            const isMicOn = !state.isMicOn;
            set({ micMeta: meta });
            get().micListeners.forEach(listener => listener(isMicOn));
            return { isMicOn };
        }),
    turnMicOn: meta => {
        console.log('[MIC On]');
        set({ micMeta: meta });
        set(() => ({ isMicOn: true }));
        get().micListeners.forEach(listener => listener(true));
    },
    turnMicOff: meta => {
        console.log('[MIC Off]');
        set({ micMeta: meta });
        set(() => ({ isMicOn: false }));
        get().micListeners.forEach(listener => listener(false));
    },

    isCaptionsOn: false,
    toggleCaptions: () => set(state => ({ isCaptionsOn: !state.isCaptionsOn })),

    isTextMode: true,
    setIsTextMode: isTextMode => {
        if (get().isTextMode !== isTextMode) {
            get().turnMicOff();
        }
        return set({ isTextMode });
    },
    toggleTextMode: () => {
        get().turnMicOff();
        return set(state => ({ isTextMode: !state.isTextMode }));
    },

    attachmentToDisplay: undefined,
    setAttachment: attachment => set({ attachmentToDisplay: attachment }),

    isSourcesDrawerOpen: false,
    openDrawer: () => set({ isSourcesDrawerOpen: true }),
    closeDrawer: () => set({ isSourcesDrawerOpen: false }),
});
