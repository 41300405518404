import config from '../consts/config';
import APIService from './APIService';
import { authService, keycloak } from './AuthenticationService';

export const ErrorsCodes = {
    ACTION_ALREADY_PERFORMED: 'action_already_performed',
    ACTION_NO_LONGER_AVAILABLE: 'action_no_longer_available',
    ACTION_NOT_AVAILABLE: 'action_not_available',
    RESOURCE_LIMIT_REACHED: 'resource_limit_reached',
    INVALID: 'invalid',
};

const apiUrl = `${config.REACT_APP_MOOC_API_ROOT_URL}${config.REACT_APP_MOOC_API_ENDPOINT}`;
const interactionsApiUrl = `${config.REACT_APP_INTERACTIONS_URL}`;
export const moocAPI = new APIService(apiUrl);
export const interactionsApi = new APIService(interactionsApiUrl);

export { keycloak, authService };
