import * as React from 'react';
import { Fragment, useState } from 'react';
import AvatarWrapper, {
    Props as AvatarProps,
} from '../../organisms/AvatarWrapper/AvatarWrapper';
import AvatarUIOverlayTemplate from '../AvatarUIOverlayTemplate/AvatarUIOverlayTemplate';
import { Props as ChatProps } from '../../organisms/Chat/Chat';
import { Center } from '@chakra-ui/react';
import LoadingSpinner from '../../../../../apps/mooc-frontend/src/components/generic/LoadingSpinner';
import { useStoreWithArray } from '../../stores';
import {
    RAPPORT_SESSION_COULD_NOT_INITIALISE,
    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
    RapportModalName,
    RapportSessionCouldNotInitialiseModal,
    RapportSessionDisconnectedTimeoutModal,
    RapportSessionDisconnectedTtsFailedModal,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import useRapportOrchestrator from './useRapportOrchestrator';

const RAPPORT_SCENE_ID = 'rapportScene';
interface Props extends ChatProps, Omit<AvatarProps, 'rapportSceneId'> {}

export default function AvatarTemplate(props: Props) {
    const [modal, setModal] = useState<RapportModalName | null>();
    const { status, initRapport } = useRapportOrchestrator(
        RAPPORT_SCENE_ID,
        props.avatar_config,
        setModal,
    );
    const { setIsTextMode } = useStoreWithArray(['setIsTextMode']);

    const isLoading = status === 'connecting';

    return (
        <Fragment>
            <AvatarWrapper rapportSceneId={RAPPORT_SCENE_ID} {...props} />

            {isLoading && (
                <Center position='fixed' w='100%' h='100%' top='0'>
                    <LoadingSpinner />
                    <span className='ml-2'> Loading </span>
                </Center>
            )}
            {!isLoading && <AvatarUIOverlayTemplate {...props} />}

            <RapportSessionCouldNotInitialiseModal
                show={modal === RAPPORT_SESSION_COULD_NOT_INITIALISE.code}
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <RapportSessionDisconnectedTtsFailedModal
                show={
                    modal ===
                    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                useTextChat={() => {
                    setIsTextMode(true);
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
            <RapportSessionDisconnectedTimeoutModal
                show={
                    modal ===
                    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code
                }
                onClose={() => setModal(null)}
                finish={() => {
                    setModal(null);
                }}
                tryAgainAvatar={() => {
                    initRapport(() => setModal(null));
                }}
            />
        </Fragment>
    );
}
